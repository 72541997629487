import StepButtons from "./js/generic/step-buttons"

document.addEventListener("DOMContentLoaded", () => {
  StepButtons(document.querySelectorAll("[data-component='step-button']"))

  // If a hash link is included, check if it corresponds to a toggle and open if so
  if (window.location.hash) {
    const anchorEl = document.getElementById(window.location.hash.substr(1))
    if (!anchorEl) return

    let toggleEl, contentEl

    if (anchorEl.getAttribute("aria-controls")) {
      toggleEl = anchorEl
      contentEl = document.getElementById(
        anchorEl.getAttribute("aria-controls")
      )
    } else if (anchorEl.classList.contains("hidden")) {
      contentEl = anchorEl
      toggleEl = document.querySelector(`a[aria-controls="${anchorEl.id}"]`)
    }

    if (toggleEl && contentEl) {
      toggleEl.setAttribute("aria-expanded", true)
      contentEl.classList.toggle("hidden", false)
    }
  }
})
