import { debounce } from "../utils"

export default function StepButtons(buttons) {
  if (buttons.length !== 2) return
  const [left, right] = buttons
  const itemEl = document.querySelector(left.dataset.item)
  if (!itemEl) return
  const size = +itemEl.clientWidth
  const target = document.querySelector(left.dataset.stepTarget)
  const container = right.dataset.container
    ? document.querySelector(right.dataset.container)
    : null

  const onScrollFinish = debounce(() => {
    const scrollLeftMax = target.scrollWidth - target.clientWidth
    left.toggleAttribute("disabled", target.scrollLeft <= size * 0.25)
    const atScrollEnd = scrollLeftMax - target.scrollLeft <= size * 0.25
    right.toggleAttribute("disabled", atScrollEnd)
    if (container) container.classList.toggle("inactive", atScrollEnd)
  }, 250)

  // Run on initial setup
  onScrollFinish()

  window.addEventListener("resize", onScrollFinish)
  target.addEventListener("scroll", onScrollFinish)

  buttons.forEach((button) => {
    const { direction } = button.dataset
    button.addEventListener("click", () => {
      const { scrollLeft, scrollWidth } = target
      const scrollLeftMax = scrollWidth
      const scrollPos =
        direction === "right"
          ? Math.min(scrollLeftMax, scrollLeft + size)
          : Math.max(0, scrollLeft - size)

      target.scroll({ left: scrollPos, behavior: "smooth" })
      // Disable button during transition
      onScrollFinish()
    })
  })
}
